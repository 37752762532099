/*###########################################################################
// Header
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'
import { scrollTo } from "../utilities/_scrollTo"

const header = {
	config: {
		main: 'main',
		header: '.header',
		headerNav: '.header__nav',
		headerMenuBtn: '.header__menu-btn',
		sectionsNav: '.header__sections-nav',
	},

	elements: {},

	init() {
		const self = this

		self.elements.header = document.querySelector(self.config.header)

		if (self.elements.header !== null) {
			self.elements.main = document.querySelector(self.config.main)
			self.elements.headerNav = self.elements.header.querySelector(self.config.headerNav)
			self.elements.headerMenuBtn = self.elements.header.querySelector(self.config.headerMenuBtn)
			self.elements.headerMenuBtn = self.elements.header.querySelector(self.config.headerMenuBtn)
			self.elements.sectionsNav = self.elements.header.querySelector(self.config.sectionsNav)


			// Handle scroll
			//
			self.handleScroll()

			// // Handle menu btn
			// //
			self.handleMenuBtn()
			self.handleSectionClick()

			self.handleActiveSections()

			window.addEventListener('resize', throttle(function () {

			}, 250))
		}
	},

	handleScroll() {
		const self = this

		let scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    // Initial check
    //
    if (scrollPosition > 20) {
      self.elements.main.classList.add('header--scrolled')
      self.elements.header.classList.add('header--scrolled')
    } else {
      self.elements.main.classList.remove('header--scrolled')
      self.elements.header.classList.remove('header--scrolled')
    }

		if (self.elements.sectionsNav.parentNode.getBoundingClientRect().top < 20) {
			self.elements.sectionsNav.classList.add('fixed')
		} else {
			self.elements.sectionsNav.classList.remove('fixed')
		}

    window.addEventListener('scroll', throttle(function () {
      scrollPosition = window.scrollY || document.documentElement.scrollTop

      if (scrollPosition > 40) {
        self.elements.main.classList.add('header--scrolled')
        self.elements.header.classList.add('header--scrolled')
      } else {
        self.elements.main.classList.remove('header--scrolled')
        self.elements.header.classList.remove('header--scrolled')
      }

			if (self.elements.sectionsNav.parentNode.getBoundingClientRect().top < 20) {
				self.elements.sectionsNav.classList.add('fixed')
			} else {
				self.elements.sectionsNav.classList.remove('fixed')
			}
		}, 100))
	},

	handleSectionClick() {
		const self = this
		const elements = document.querySelectorAll('[data-scroll-to]')

		// Loop through each element and add the event listener
		elements.forEach(element => {
				element.addEventListener('click', function(e) {
					e.preventDefault()
						// Get the target section's ID from the data attribute
						const self = this
						const targetId = self.getAttribute('data-scroll-to');


						// Use querySelector to get a single element
						const targetSection = document.getElementById(`${targetId}`)
						const headerHeight = document.querySelector('.header').clientHeight

						scrollTo(targetSection, -headerHeight)
				});
		});

	},

	handleActiveSections() {
		const self = this

		// Get all sections that have an ID defined
		const sections = document.querySelectorAll('div[data-block]')

		// Add an event listener listening for scroll
		window.addEventListener("scroll", navHighlighter);
		function navHighlighter() {

			// Get current scroll position
			let scrollY = window.scrollY;

			// Now we loop through sections to get height, top and ID values for each
			sections.forEach(current => {
				const sectionHeight = current.offsetHeight
				const sectionTop = current.offsetTop - window.innerHeight/2
				const sectionId = current.getAttribute("id")

				/*
				- If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
				- To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
				*/
				if (
					scrollY > sectionTop &&
					scrollY <= sectionTop + sectionHeight
				){
					document.querySelector(".header__section-link[href='" + sectionId + "']").classList.add("header__section-link--active");
				} else {
					document.querySelector(".header__section-link[href='" + sectionId + "']").classList.remove("header__section-link--active");
				}
			});
		}
	},

	handleMenuBtn() {
		const self = this

		self.elements.headerMenuBtn.addEventListener('click', function (e) {
			e.preventDefault()

			if (self.elements.header.classList.contains('header--open')) {
				self.elements.header.classList.remove('header--open')
				self.elements.headerMenuBtn.innerHTML = "menu"
				window.app.enableScroll()

				// Reset sub menus
				//
				setTimeout(function () {
					for (let i = 0; i < self.elements.headerSubMenuTriggers.length; i++) {
						const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[i]
						const headerSubMenu = headerSubMenuTrigger.nextElementSibling
						headerSubMenu.classList.remove('close')
						headerSubMenu.scrollTop = 0
					}

					// Remove potential classes
					//
					self.elements.header.classList.remove('header--sub-open')
					self.elements.headerNav.classList.remove('overflow-hidden')

					// Reset nav scroll
					//
					self.elements.headerNav.scrollTop = 0

					// Reset nav overflow
					//
					self.elements.headerNav.style.overflowY = ''
				}, 500)
			} else {
				self.elements.header.classList.add('header--open')
				self.elements.headerMenuBtn.innerHTML = "close"
				window.app.disableScroll()
			}
		})
	},
}

window.addEventListener('load', function () {
	header.init()
})
