/*###########################################################################
// Bubble Hover
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/nearby'
// import { throttle } from "../utilities/throttleDebounce"
// import { inViewport } from "@js/utilities/inViewport"

gsap.registerPlugin(ScrollTrigger)

const background__animation = {
  config: {
    background__animation: '.background__animation'
  },

  elements: {},

  init() {
    const self = this

    self.elements.background__animations = document.querySelectorAll(self.config.background__animation)

    if (self.elements.background__animations !== null) {
      for (let i = 0; i < self.elements.background__animations.length; i++) {
        const background__animation = self.elements.background__animations[i]

        self.handleBackgroundAnimation(background__animation)
      }
    }
  },

  handleBackgroundAnimation(background__animation) {
    const self = this

    const blob1 = background__animation.querySelector('.background__animation__blob1')
    const blob2 = background__animation.querySelector('.background__animation__blob2')

    var tlBlob1 = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    var tlBlob2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });

    tlBlob1.to(blob1, { x: 40, y: 40, duration: 3 })
        .to(blob1, { x: 60, y: 0, duration: 3 })
        .to(blob1, { x: 40, y: -40, duration: 3 })
        .to(blob1, { x: 0, y: -60, duration: 3 })
        .to(blob1, { x: -40, y: -40, duration: 3 })
        .to(blob1, { x: -60, y: 0, duration: 3 })
        .to(blob1, { x: -40, y: 40, duration: 3 })
        .to(blob1, { x: 0, y: 0, duration: 3 }); 

    tlBlob2.to(blob2, { x: -40, y: -40, duration: 3 })
        .to(blob2, { x: -60, y: 0, duration: 3 })
        .to(blob2, { x: -40, y: 40, duration: 3 })
        .to(blob2, { x: 0, y: 60, duration: 3 })
        .to(blob2, { x: 40, y: 40, duration: 3 })
        .to(blob2, { x: 60, y: 0, duration: 3 })
        .to(blob2, { x: 40, y: -40, duration: 3 })
        .to(blob2, { x: 0, y: 0, duration: 3 }); 

  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    background__animation.init()
  }, 1000)
})
