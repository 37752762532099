/*###########################################################################
// Clipboard
//#########################################################################*/

import ClipboardJS from 'clipboard'

window.addEventListener('load', function() {
	const buttons = document.querySelectorAll('.copy-clipboard')

	if (buttons !== null) {
		for (let i = 0; i < buttons.length; i++) {

			let clipboard = new ClipboardJS(buttons[i])
			let defaultText = ''
			if (buttons[i].getAttribute('data-clipboard-inner') == 'true') {
				defaultText = buttons[i].querySelector('.inner').innerText
			} else {
				defaultText = buttons[i].innerText
			}

			clipboard.on('success', function (e) {
				let target = buttons[i]

				if (target.getAttribute('data-clipboard-inner') == 'true') {
					const text = target.querySelector('.inner')
					text.innerText = 'Copied'

					setTimeout(() => {
						text.innerText = defaultText
					}, 2000)
				} else {
					target.innerText = 'Copied'

					setTimeout(() => {
						target.innerText = defaultText
					}, 2000)
				}
			});

			clipboard.on('error', function (e) {
				console.error('Action:', e.action)
				console.error('Trigger:', e.trigger)
			});
		}
	}
})
