/*###########################################################################
// Bubble Hover
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/nearby'
// import { throttle } from "../utilities/throttleDebounce"
// import { inViewport } from "@js/utilities/inViewport"

gsap.registerPlugin(ScrollTrigger)

const bubbleHover = {
  config: {
    bubbleHover: '.bubbleHover',
    bubbleHoverRoles: '.bubbleHover__roles',
    bubbleHoverTitle: '.bubbleHover__title',
    bubbleHoverGradients: '.bubbleHover__gradients',
    bubbleHoverContent: '.bubbleHover__content',
  },

  elements: {},

  init() {
    const self = this

    self.elements.bubbleHovers = document.querySelectorAll(self.config.bubbleHover)

    if (self.elements.bubbleHovers !== null) {
      for (let i = 0; i < self.elements.bubbleHovers.length; i++) {
        const bubbleHover = self.elements.bubbleHovers[i]

        self.handleBubbleHover(bubbleHover)
      }
    }
  },

  handleBubbleHover(bubbleHover) {
    const self = this

    const bubbleHoverRoles = bubbleHover.querySelector(self.config.bubbleHoverRoles)
    gsap.set(bubbleHoverRoles, { opacity: 0, scale: 0.9 });

    const bubbleHoverTitle = bubbleHover.querySelector(self.config.bubbleHoverTitle)
    gsap.set(bubbleHoverTitle, { opacity: 0 });

    const bubbleHoverGradients = bubbleHover.querySelector(self.config.bubbleHoverGradients)
    gsap.set(bubbleHoverGradients, { opacity: 0 });

    const bubbleHoverContent = bubbleHover.querySelector(self.config.bubbleHoverContent)
    gsap.set(bubbleHoverContent, { opacity: 0 });

    const bubbleHoverLogin = bubbleHover.querySelector(self.config.bubbleHoverLogin)
    gsap.set(bubbleHoverLogin, { opacity: 0 });

    const enter = (scrollTriggerInstance) => {
      gsap.to(bubbleHoverRoles, {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(bubbleHoverTitle, {
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(bubbleHoverGradients, {
        opacity: 0.8,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.to(bubbleHoverContent, {
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      });

      gsap.fromTo(bubbleHoverGradients, {
        rotate: 0,
        ease: "none",
      }, {
        rotate: 360,
        repeat: -1,
        duration: 10,
        ease: "none",
      });

      if (window.innerWidth >= 1024) {
        self.handleProximityEffect(bubbleHoverGradients)
      }

      scrollTriggerInstance.kill(false, true)
    };

    ScrollTrigger.create({
      trigger: bubbleHover,
      pin: false,
      start: "top +=" + window.innerHeight/2 + " middle",
      markers: false,
      onEnter: enter,
      onLeave: self => self.kill(false, true)
    });

    // Ensure scrolltrigger positions are correct when near element
    //
    setTimeout(function() {
      ScrollTrigger.sort()
    }, 500)
  },

  handleProximityEffect(gradientEl) {
    const self = this;

    let distanceThreshold = {min: 0, max: 500};
    let scaleInterval = {from: 1, to: 1.35};
    let opacityInterval = {from: 0.8, to: 1};
    let translateInterval = {from: 0, to: 10};

    const nearbyInstance = new Nearby(gradientEl, {
      onProgress: (distance) => {
        let scale = window.lineEq(scaleInterval.from, scaleInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
        let opacity = window.lineEq(opacityInterval.from, opacityInterval.to, distanceThreshold.max, distanceThreshold.min, distance);
        let translate = window.lineEq(translateInterval.from, translateInterval.to, distanceThreshold.max, distanceThreshold.min, distance);

        gsap.to(gradientEl, 2, {
          ease: 'Expo.easeOut',
          scale: Math.max(scale, scaleInterval.from),
          opacity: opacity,
          x: -(Math.max(translate, translateInterval.from)),
          y: -(Math.max(translate, translateInterval.from))
        });
      }
    })
  },

  killNearbyInstance() {
    const self = this;

    self.nearbyInstance.kill();
  },
}

window.addEventListener('load', function () {
  setTimeout(function() {
    bubbleHover.init()
  }, 1000)
})
