/*###########################################################################
// Resources filter
//#########################################################################*/

/*###########################################################################
// Insights filter
//#########################################################################*/

// import { scrollTo } from "../utilities/scrollTo"

const insightsFilter = {
  config: {
    paginationBtn: '.pagination .btn',
    filterBtn: '.pillBtn',
    filterSelect: '#categoryId'
  },

  elements: {
    paginationBtns: null,
    filterBtns: null,
    filterSelect: null
  },

  init() {
    const self = this

    if (document.querySelector('#entry-container') !== null) {
      self.elements.paginationBtns = document.querySelectorAll(self.config.paginationBtn)


      self.elements.filterBtns = document.querySelectorAll(self.config.filterBtn)
      if (self.elements.filterBtns !== null) {
        for (let i = 0; i < self.elements.filterBtns.length; i++) {
          const filterBtn = self.elements.filterBtns[i]
          const selectToSync = document.querySelector(filterBtn.getAttribute('data-select'))

          filterBtn.addEventListener('click', function() {
            // Reset buttons
            for (let i = 0; i < self.elements.filterBtns.length; i++) {
              const filterBtn = self.elements.filterBtns[i]

              filterBtn.classList.remove('pillBtn--dark')
            }

            // Set current button
            filterBtn.classList.add('pillBtn--dark')

            selectToSync.value = filterBtn.getAttribute('data-value')

            // Trigger a change event for sprig so it updates
            let changeEvent = new Event('change')
            selectToSync.dispatchEvent(changeEvent)
          })
        }
      }
    }
  },

  handlePaginationClick() {
    const self = this

    // Scroll to entries
    self.scrollToContainer(false)
  },

  scrollToContainer(filterClick) {
    const self = this

    const offsetAmount = document.querySelector('.header').clientHeight + 40

    window.lenis.scrollTo(document.querySelector('#insights-container').parentNode, {
      offset: -offsetAmount,
      duration: filterClick ? 0.25 : 1.25,
      easing: (t) => -(Math.cos(Math.PI * t) - 1) / 2
    })
  }
}

window.addEventListener('load', function () {
  insightsFilter.init()
})
