/*###########################################################################
// App
//#########################################################################*/

/* CSS
//------------------*/

import "@css/app.pcss";

/* App
//------------------*/

window.app = {
  disableScroll() {
    document.documentElement.style.overflow = 'hidden'
  },

  enableScroll() {
    document.documentElement.style.overflow = ''
  }
}

/* Components
//------------------*/

import '@js/components/index'

/* Vendor
//------------------*/

import '@js/vendor/index'

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
*/
import Alpine from 'alpinejs';
//import alpineComponent from "@/js/components/components";

/* Register components */
//Alpine.data('alpineComponent', alpineComponent);

Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
